import GoogleLogo from "./assets/google.svg?react";
import MicrosoftLogo from "./assets/microsoft.svg?react";
import OktaLogo from "./assets/okta.svg?react";
import AmazonLogo from "./assets/amazon.svg?react";

export const providerToLogo: {
  [provider: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} = {
  google: GoogleLogo,
  microsoft: MicrosoftLogo,
  okta: OktaLogo,
  amazon: AmazonLogo,
};